import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { Controller, SubmitHandler, useFieldArray, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import isFQDN from 'validator/lib/isFQDN';
import isInt from 'validator/lib/isInt';
import { DropdownOption } from '../../../../types';
import { PostGWSStdAppPayload } from '../../../../types/application/constants';
import {
  isNavBarOpenAtom,
  isToastOpenAtom,
  navBarWidthAtom,
  toastContentAtom,
} from '../../../../store/atoms';
import {
  curSubFormStepAtom,
  postGWSStdAppPayloadAtom,
  postStdAppAttPayloadAtom,
} from '../../../../store/application/atoms';
import { yesNoOptions } from '../../../../store/constants';
import { getInitDomainInfo, getWorkspacePlanVariant } from '../../../../utils/get-variant';
import {
  usePatchStdApp,
  usePostStdApp,
} from '../../../../services/application/verified-standard/app.api';
import { usePostStdAppAtt } from '../../../../services/application/verified-standard/attachment.api';
import {
  useGetListPriceCurrencyOptions,
  useGetMonthOptions,
  useGetOfficialPricingDiscountOptions,
  useGetPUPPTypeOptions,
  useGetSupportPlanOptions,
  useGetWorkspaceEditionOptions,
} from '../../../../services/application/verified-standard/dropdown.api';
import { scrollToFirstError, scrollToTop } from '../../../../utils/scroll';
import { motion } from 'framer-motion';
import Box from '../../../../components/box';
import { ButtonText, Caption, Text } from '../../../../components/typography/text';
import { Heading5 } from '../../../../components/typography/heading';
import Spacer from '../../../../components/surface/spacer';
import ColorfulChip from '../../../../components/chip/colorful-chip';
import RadioGroup from '../../../../components/field/radio-group';
import {
  DialogAlertCancel,
  DialogAlertContent,
  DialogAlertDescription,
  DialogAlertOverlay,
  DialogAlertPortal,
  DialogAlertRoot,
  DialogAlertTitle,
  DialogAlertTrigger,
} from '../../../../components/helper/dialog-alert';
import Button from '../../../../components/button/button';
import MSymbol from '../../../../components/icon/m-symbol';
import Combobox from '../../../../components/dropdown/combobox';
import Label from '../../../../components/typography/label';
import TextInputArea from '../../../../components/field/text-input-area';
import TextInput from '../../../../components/field/text-input';
import ChipSelect from '../../../../components/dropdown/chip-select';
import WizardBar from '../../../../components/bar/wizard-bar';
import {
  SubmissionColumn,
  SubmissionPaper,
  SubmissionPaperHeader,
  SubmissionRow,
  SubmissionSection,
  SubmissionSectionHeader,
} from '../../../../components/surface/submission-paper';
import { CenteredBoxFlex } from '../../../../components/surface/centered-box';
import IconButton from '../../../../components/button/icon-button';
import ServiceItemButton from '../../../../components/button/service-item-button';
import Combofield from '../../../../components/dropdown/combofield';
import { fadeInOutMotion, fixedLayoutGrowMotion } from '../../../../styles/motions';
import LogoGoogleWorkspace from '../../../../assets/images/logo-google-workspace.svg';

const validationSchema: Yup.AnyObjectSchema = Yup.object().shape({
  official_pricing_discount_type: Yup.object().shape({
    value: Yup.string().required(),
  }),
  official_pricing_discount_value: Yup.number()
    .typeError('Must specify a number')
    .test('official_pricing_discount_value', '', (value, context) => {
      const curOfficialPricingDiscountType =
        context.from?.[0].value.official_pricing_discount_type.value;
      if (curOfficialPricingDiscountType === 'percentage_discount') {
        return (
          (!!value &&
            parseFloat(value.toString()) > 0 &&
            parseFloat(value.toString()) <= 100 &&
            /^\d+(\.\d{1,2})?$/.test(value.toString())) ||
          context.createError({ message: `Positive number <= 100, up to 2 decimal places` })
        );
      } else if (curOfficialPricingDiscountType === 'fixed_amount_discount') {
        return (
          (!!value &&
            parseFloat(value.toString()) > 0 &&
            /^\d+(\.\d{1,2})?$/.test(value.toString())) ||
          context.createError({ message: `Positive number, up to 2 decimal places` })
        );
      } else {
        return true;
      }
    }),
  official_pricing_discount_currency: Yup.object().shape({
    value: Yup.string().test(
      'official_pricing_discount_currency',
      'This field is required',
      (value, context) => {
        const curOfficialPricingDiscountType =
          context.from?.[1].value.official_pricing_discount_type.value;
        return curOfficialPricingDiscountType === 'no_discount' || value !== '';
      }
    ),
  }),
  support_plan: Yup.object().shape({
    value: Yup.string().required(),
  }),
  domain_infos: Yup.array().of(
    Yup.object().shape({
      has_signed_domain_quotation: Yup.string().required(),
      domain_name: Yup.string()
        .required('This field is required')
        .test('domain_name', 'Not fully qualified domain name', (value) => {
          return isFQDN(value);
        }),
      workspace_edition_and_plan: Yup.object().shape({
        value: Yup.string().required('This field is required'),
      }),
      subscription_term: Yup.number()
        .typeError('Must specify a number')
        .moreThan(-1, 'Must greater or equal to 0')
        .integer('Must be an integer')
        .required('This field is required'),
      seats: Yup.string()
        .nullable()
        .notRequired()
        .test('seats', 'Must be an integer', (value) => (value ? isInt(value) : true))
        .test('seats', 'Must greater than 0', (value) => (value ? parseInt(value) > 0 : true)),
      special_price_from_google: Yup.string().required('This field is required'),
      google_pupp_type: Yup.object().shape({
        value: Yup.mixed().test('google_pupp_type', 'This field is required', (value, context) => {
          if (
            context.from?.[2].value.domain_infos[(context as any).options.index]
              .special_price_from_google === 'yes'
          ) {
            return !!value;
          } else {
            return true;
          }
        }),
      }),
      google_pupp_value: Yup.number()
        .test('google_pupp_value', '', (value, context) => {
          if (context.parent.special_price_from_google === 'yes') {
            return (
              (!!value &&
                parseFloat(value.toString()) > 0 &&
                /^\d+(\.\d{1,2})?$/.test(value.toString())) ||
              context.createError({ message: `Positive number, up to 2 decimal places` })
            );
          } else {
            return true;
          }
        })
        .typeError('Must specify a number'),
      google_pupp_currency: Yup.object().shape({
        value: Yup.string().required('This field is required'),
      }),
    })
  ),
  reason_for_discount_rate: Yup.string().test(
    'reason_for_discount_rate',
    'When percentage discount rate is greater than or equal to 5%, this field is required',
    (value, context) => {
      const curOfficialPricingDiscountType =
        context.from?.[0].value.official_pricing_discount_type.value;
      const curOfficialPricingDiscountValue =
        context.from?.[0].value.official_pricing_discount_value;
      return (
        curOfficialPricingDiscountType !== 'percentage_discount' ||
        curOfficialPricingDiscountValue < 5 ||
        value !== ''
      );
    }
  ),
  memo: Yup.string().optional(),
});

const ServiceInfo = () => {
  const navigate = useNavigate();
  const { appId } = useParams();
  const navBarWidth = useAtomValue(navBarWidthAtom);
  const isNavBarOpen = useAtomValue(isNavBarOpenAtom);
  const setIsToastOpen = useSetAtom(isToastOpenAtom);
  const setToastContent = useSetAtom(toastContentAtom);
  const setCurSubFormStep = useSetAtom(curSubFormStepAtom);
  const [postStdAppPayload, setPostStdAppPayload] = useAtom(postGWSStdAppPayloadAtom);
  const postStdAppAttPayload = useAtomValue(postStdAppAttPayloadAtom);

  const { control, handleSubmit, getValues, setValue } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: postStdAppPayload,
    resolver: yupResolver(validationSchema),
  });
  const curOfficialPricingDiscountType = useWatch({
    control,
    name: 'official_pricing_discount_type',
  });
  const curOfficialPricingDiscountValue = useWatch({
    control,
    name: 'official_pricing_discount_value',
  });
  const curDomainInfos = useWatch({
    control,
    name: 'domain_infos',
  });

  const {
    fields: domainInfosFields,
    append: domainInfosAppend,
    remove: domainInfosRemove,
  } = useFieldArray<PostGWSStdAppPayload>({
    control,
    name: 'domain_infos',
  });

  const officialPricingDiscountOptionsQuery = useGetOfficialPricingDiscountOptions();
  const officialPricingDiscountCurrencyOptionsQuery = useGetListPriceCurrencyOptions({
    currencyType: getValues('currency.value'),
    cmLegalEntity: getValues('cm_legal_entity.value'),
    exchangeRate: getValues('exchange_rate.value') || undefined,
  });

  const supportPlanOptionsQuery = useGetSupportPlanOptions({ product: getValues('product') });
  const subscriptionTermOptionsQuery = useGetMonthOptions({ field: 'subscription_term' });
  const workspaceEditionAndPlanQuery = useGetWorkspaceEditionOptions();
  const PUPPTypeQuery = useGetPUPPTypeOptions();
  const postAppMutation = usePostStdApp();
  const patchAppMutation = usePatchStdApp({ appId: appId || '' });
  const postAttachmentMutation = usePostStdAppAtt({
    appId,
    onSuccess: (res, appStatus) => {
      if (appId) {
        patchAppMutation.mutate({ ...getValues(), ...res, status: appStatus });
      } else {
        if (appStatus === 'draft') {
          postAppMutation.mutate({ ...getValues(), ...res, status: appStatus });
        } else {
          postAppMutation.mutate({ ...postStdAppPayload, ...res, status: appStatus });
        }
      }
    },
    onError: (err) => {
      setToastContent({
        isError: true,
        error: `${err.response?.data.message ?? '-'}`,
      });
      setIsToastOpen(true);
    },
  });

  const onOfficialPricingDiscountTypeChange = (newValue: {
    name: string;
    value: DropdownOption['value'];
  }) => {
    setValue('official_pricing_discount_type', newValue, { shouldValidate: true });
    setValue('official_pricing_discount_value', 0, { shouldValidate: true });
  };

  const onWorkspaceEditionAndPlanChange = (
    newValue: {
      name: string;
      value: DropdownOption['value'];
    },
    domainInfoIndex: number
  ) => {
    setValue(`domain_infos.${domainInfoIndex}.workspace_edition_and_plan`, newValue, {
      shouldValidate: true,
    });
    setValue(`domain_infos.${domainInfoIndex}.workspace_edition`, {
      name: newValue.name,
      value: (newValue.value as string).split(',')[0],
    });
    const selectedWorkspaceEditionAndPlan = workspaceEditionAndPlanQuery.data?.find(
      (option) => option.value === newValue.value
    );
    if (selectedWorkspaceEditionAndPlan) {
      setValue(`domain_infos.${domainInfoIndex}.workspace_plan`, {
        name: selectedWorkspaceEditionAndPlan.chip,
        value: (selectedWorkspaceEditionAndPlan.value as string).split(',')[1],
      });
    }
  };

  const onSpecialPriceFromGoogleChange = (newValue: string, domainInfoIndex: number) => {
    setValue(`domain_infos.${domainInfoIndex}.special_price_from_google`, newValue, {
      shouldValidate: true,
    });
    setValue(
      `domain_infos.${domainInfoIndex}.google_pupp_type`,
      { name: '', value: '' },
      {
        shouldValidate: true,
      }
    );
    setValue(`domain_infos.${domainInfoIndex}.google_pupp_value`, undefined);
  };
  const onBackClick = () => {
    setPostStdAppPayload((prev) => ({ ...prev, ...getValues() }));
    setCurSubFormStep(1);
  };
  const onNextClick: SubmitHandler<PostGWSStdAppPayload> = (data) => {
    setPostStdAppPayload((prev) => ({ ...prev, ...data }));
    setCurSubFormStep(3);
  };
  const onNextError = (errors: unknown) => {
    scrollToFirstError(errors);
  };
  const onSaveClick = () => {
    postAttachmentMutation.mutate({
      ...postStdAppAttPayload,
      appStatus: appId ? getValues('status') : 'draft',
    });
  };
  const onLeaveClick = () => {
    navigate('/applications');
  };

  useEffect(() => {
    if (
      officialPricingDiscountCurrencyOptionsQuery.isSuccess &&
      officialPricingDiscountCurrencyOptionsQuery.data.length > 0
    ) {
      if (!getValues('official_pricing_discount_currency.value')) {
        setValue(
          'official_pricing_discount_currency',
          {
            name: officialPricingDiscountCurrencyOptionsQuery.data[0].name,
            value: officialPricingDiscountCurrencyOptionsQuery.data[0].value,
          },
          { shouldValidate: true }
        );
      }
    }
  }, [
    officialPricingDiscountCurrencyOptionsQuery.data,
    officialPricingDiscountCurrencyOptionsQuery.isSuccess,
    getValues,
    setValue,
  ]);

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <form onSubmit={handleSubmit(onNextClick, onNextError)}>
      {/* Service */}
      <SubmissionPaper>
        {/* Header */}
        <SubmissionPaperHeader>
          <Heading5>Service</Heading5>
          <ColorfulChip text="GWS" color="tangerine" variant="solid" />
        </SubmissionPaperHeader>
        {/* Payment Details */}
        <SubmissionSection>
          <SubmissionSectionHeader>
            <Text semibold>Payment Details</Text>
          </SubmissionSectionHeader>
          <ServiceItemButton type="button" data-state="active" showcase>
            <Box as="img" src={LogoGoogleWorkspace} css={{ py: 14 }} />
          </ServiceItemButton>
          <SubmissionRow>
            {/* Support Plan */}
            <SubmissionColumn>
              <Controller
                control={control}
                name="support_plan"
                render={({ field: { onChange, value, name }, fieldState: { invalid, error } }) => (
                  <Combobox
                    required
                    label="Support Plan"
                    placeholder="Select a support plan"
                    isLoading={supportPlanOptionsQuery.isLoading}
                    options={supportPlanOptionsQuery.data || []}
                    fieldName={name}
                    value={value}
                    onChange={onChange}
                    isError={invalid}
                    error={error ? 'This field is required' : ''}
                  />
                )}
              />
            </SubmissionColumn>
            {/* Official Pricing Discount from CM */}
            <SubmissionColumn>
              <Label>
                <Caption>
                  Official Pricing Discount from CM
                  <Caption css={{ color: '$error50' }}>{` *`}</Caption>
                </Caption>
              </Label>
              <SubmissionRow columnGapSize="5">
                {/* Official Pricing Discount Type  */}
                <Box css={{ width: `calc(50% - ${5 * (5 / 3)}px)` }}>
                  <Controller
                    control={control}
                    name="official_pricing_discount_type"
                    render={({ field: { value, name }, fieldState: { invalid, error } }) => (
                      <Combobox
                        placeholder="Select a discount type"
                        isLoading={officialPricingDiscountOptionsQuery.isLoading}
                        options={officialPricingDiscountOptionsQuery.data || []}
                        optionSize="lg"
                        fieldName={name}
                        value={value}
                        onChange={onOfficialPricingDiscountTypeChange}
                        isError={invalid}
                        error={error ? 'This field is required' : ''}
                      />
                    )}
                  />
                </Box>
                {/* Official Pricing Discount Value  */}
                <Box css={{ width: `calc(30% - ${5 * (5 / 3)}px)` }}>
                  <Controller
                    control={control}
                    name="official_pricing_discount_value"
                    render={({
                      field: { onChange, value, name },
                      fieldState: { invalid, error },
                    }) => (
                      <TextInput
                        placeholder="Enter a discount value"
                        fieldName={name}
                        value={value}
                        onChange={onChange}
                        disabled={curOfficialPricingDiscountType.value === 'no_discount'}
                        isError={invalid}
                        error={error?.message}
                        endElement={
                          curOfficialPricingDiscountType.value === 'percentage_discount' ? (
                            <ButtonText size={14} css={{ color: '$text-secondary', mr: 8 }}>
                              %
                            </ButtonText>
                          ) : null
                        }
                      />
                    )}
                  />
                </Box>
                {/* Official Pricing Discount Currency */}
                <Box css={{ width: `calc(20% - ${5 * (5 / 3)}px)` }}>
                  <Controller
                    control={control}
                    name="official_pricing_discount_currency"
                    render={({
                      field: { onChange, value, name },
                      fieldState: { invalid, error },
                    }) => (
                      <ChipSelect
                        placeholder="Select a discount currency"
                        isLoading={officialPricingDiscountCurrencyOptionsQuery.isLoading}
                        options={officialPricingDiscountCurrencyOptionsQuery.data || []}
                        fieldName={name}
                        value={value}
                        onChange={onChange}
                        disabled={curOfficialPricingDiscountType.value === 'no_discount'}
                        isError={invalid}
                        error={error ? 'This field is required' : ''}
                      />
                    )}
                  />
                </Box>
              </SubmissionRow>
            </SubmissionColumn>
          </SubmissionRow>
          <SubmissionRow>
            {/* Has this customer ever signed a GWS quotation ? */}
            <SubmissionColumn widthRatio={'10'}>
              <Controller
                control={control}
                name="has_signed_quotation"
                render={({ field: { onChange, value } }) => (
                  <RadioGroup
                    required
                    label="Has this customer ever signed a GWS quotation ?"
                    value={value}
                    options={yesNoOptions}
                    onChange={onChange}
                  />
                )}
              />
            </SubmissionColumn>
          </SubmissionRow>
          {/* Domain Infos */}
          <SubmissionSection
            css={{ borderRadius: 10, backgroundColor: '$achromatic3', p: 10, rowGap: 40 }}>
            {domainInfosFields.map((domainInfoField, domainInfoIndex) => (
              <SubmissionRow key={domainInfoField.id} columnGapSize={10}>
                <SubmissionColumn
                  widthRatio={'10'}
                  css={{ display: 'flex', flexDirection: 'column', rowGap: 10 }}>
                  <ColorfulChip
                    color="sky"
                    variant="outlined"
                    text={`Domain & Workspace plan ${domainInfoIndex + 1}`}
                  />
                  <SubmissionRow>
                    {/* Has this domain ever signed a GWS quotation ? */}
                    <SubmissionColumn>
                      <Controller
                        control={control}
                        name={`domain_infos.${domainInfoIndex}.has_signed_domain_quotation`}
                        render={({ field: { onChange, value } }) => (
                          <RadioGroup
                            required
                            label="Has this domain ever signed a GWS quotation ?"
                            value={value}
                            options={yesNoOptions}
                            onChange={onChange}
                          />
                        )}
                      />
                    </SubmissionColumn>
                    {/* Domain Name */}
                    <SubmissionColumn>
                      <Controller
                        control={control}
                        name={`domain_infos.${domainInfoIndex}.domain_name`}
                        render={({
                          field: { onChange, value, name },
                          fieldState: { invalid, error },
                        }) => (
                          <TextInput
                            label="Domain Name"
                            placeholder="Enter a domain name"
                            required
                            fieldName={name}
                            value={value}
                            onChange={onChange}
                            isError={invalid}
                            error={error?.message}
                          />
                        )}
                      />
                    </SubmissionColumn>
                  </SubmissionRow>
                  <SubmissionRow>
                    {/* Workspace Edition & Plan */}
                    <SubmissionColumn widthRatio={'10'}>
                      <Controller
                        control={control}
                        name={`domain_infos.${domainInfoIndex}.workspace_edition_and_plan`}
                        render={({ field: { value, name }, fieldState: { invalid, error } }) => (
                          <Combobox
                            required
                            label="Workspace Edition & plan"
                            placeholder="Select a workspace edition & plan"
                            isLoading={workspaceEditionAndPlanQuery.isLoading}
                            options={workspaceEditionAndPlanQuery.data || []}
                            optionSize="md"
                            fieldName={name}
                            value={value}
                            onChange={(newValue) => {
                              onWorkspaceEditionAndPlanChange(newValue, domainInfoIndex);
                            }}
                            triggerEndElement={
                              curDomainInfos[domainInfoIndex]?.workspace_plan?.value ? (
                                <ColorfulChip
                                  text={curDomainInfos[domainInfoIndex]?.workspace_plan?.name}
                                  variant="solid"
                                  color={getWorkspacePlanVariant({
                                    planValue:
                                      curDomainInfos[domainInfoIndex]?.workspace_plan?.value,
                                  })}
                                />
                              ) : null
                            }
                            isError={invalid}
                            error={error ? 'This field is required' : ''}
                          />
                        )}
                      />
                    </SubmissionColumn>
                  </SubmissionRow>
                  <SubmissionRow>
                    {/* Subscription Term */}
                    <SubmissionColumn>
                      <Controller
                        control={control}
                        name={`domain_infos.${domainInfoIndex}.subscription_term`}
                        render={({
                          field: { onChange, value, name },
                          fieldState: { invalid, error },
                        }) => (
                          <Combofield
                            required
                            label="Subscription Term"
                            placeholder="Select a subscription term"
                            isLoading={subscriptionTermOptionsQuery.isLoading}
                            options={subscriptionTermOptionsQuery.data || []}
                            fieldName={name}
                            value={value}
                            onChange={onChange}
                            isError={invalid}
                            error={error?.message}
                            triggerEndElement={
                              <ButtonText size={14} css={{ color: '$text-secondary', mr: 8 }}>
                                Months
                              </ButtonText>
                            }
                          />
                        )}
                      />
                    </SubmissionColumn>
                    {/* Seats */}
                    <SubmissionColumn>
                      <Controller
                        control={control}
                        name={`domain_infos.${domainInfoIndex}.seats`}
                        render={({
                          field: { onChange, value, name },
                          fieldState: { invalid, error },
                        }) => (
                          <TextInput
                            label="Seats"
                            placeholder="Enter a seat amount"
                            fieldName={name}
                            value={value}
                            onChange={onChange}
                            isError={invalid}
                            error={error?.message}
                          />
                        )}
                      />
                    </SubmissionColumn>
                  </SubmissionRow>
                  <SubmissionRow>
                    {/* Special Price from Google */}
                    <SubmissionColumn>
                      <Controller
                        control={control}
                        name={`domain_infos.${domainInfoIndex}.special_price_from_google`}
                        render={({ field: { value }, fieldState: { invalid, error } }) => (
                          <RadioGroup
                            required
                            label="Special price from Google"
                            value={value}
                            options={yesNoOptions}
                            onChange={(newValue) =>
                              onSpecialPriceFromGoogleChange(newValue, domainInfoIndex)
                            }
                            isError={invalid}
                            error={error?.message}
                          />
                        )}
                      />
                    </SubmissionColumn>
                    {/* PUPM / PUPY from Google */}
                    <SubmissionColumn>
                      <Label>
                        <Caption>
                          PUPM / PUPY from Google
                          {curDomainInfos[domainInfoIndex]?.special_price_from_google === 'yes' ? (
                            <Caption css={{ color: '$error50' }}>{` *`}</Caption>
                          ) : null}
                        </Caption>
                      </Label>
                      <SubmissionRow columnGapSize="5">
                        {/* Google PUPP Type  */}
                        <Box css={{ width: `calc(50% - ${5 * (5 / 3)}px)` }}>
                          <Controller
                            control={control}
                            name={`domain_infos.${domainInfoIndex}.google_pupp_type`}
                            render={({
                              field: { onChange, value, name },
                              fieldState: { invalid, error },
                            }) => (
                              <Combobox
                                placeholder="Select PUPP or PUPY"
                                isLoading={PUPPTypeQuery.isLoading}
                                options={PUPPTypeQuery.data || []}
                                optionSize="md"
                                fieldName={name}
                                value={value}
                                onChange={onChange}
                                isError={invalid}
                                error={error ? 'This field is required' : ''}
                              />
                            )}
                          />
                        </Box>
                        {/* Google PUPP Value  */}
                        <Box css={{ width: `calc(30% - ${5 * (5 / 3)}px)` }}>
                          <Controller
                            control={control}
                            name={`domain_infos.${domainInfoIndex}.google_pupp_value`}
                            render={({
                              field: { onChange, value, name },
                              fieldState: { invalid, error },
                            }) => (
                              <TextInput
                                placeholder="Enter a value"
                                fieldName={name}
                                value={value}
                                onChange={onChange}
                                isError={invalid}
                                error={error?.message}
                              />
                            )}
                          />
                        </Box>
                        {/* Google PUPP Currency */}
                        <Box css={{ width: `calc(20% - ${5 * (5 / 3)}px)` }}>
                          <Controller
                            control={control}
                            name={`domain_infos.${domainInfoIndex}.google_pupp_currency`}
                            render={({
                              field: { onChange, value, name },
                              fieldState: { invalid, error },
                            }) => (
                              <ChipSelect
                                placeholder="Select a currency"
                                isLoading={false}
                                options={[]}
                                fieldName={name}
                                value={value}
                                disabled
                                onChange={onChange}
                                isError={invalid}
                                error={error ? 'This field is required' : ''}
                              />
                            )}
                          />
                        </Box>
                      </SubmissionRow>
                    </SubmissionColumn>
                  </SubmissionRow>
                </SubmissionColumn>
                {/* Domain Infos Add or Remove Button */}
                <CenteredBoxFlex css={{ height: 30, mt: 51 }}>
                  <IconButton
                    type="button"
                    color={domainInfoIndex > 0 ? 'error' : 'primary'}
                    size="mini"
                    variant="outlined"
                    iconName={domainInfoIndex > 0 ? 'remove' : 'add'}
                    disabled={domainInfoIndex <= 0 && domainInfosFields.length >= 10}
                    onClick={() => {
                      if (domainInfoIndex > 0) {
                        domainInfosRemove(domainInfoIndex);
                      } else {
                        domainInfosAppend(
                          getInitDomainInfo({
                            initPUPPFromGoogleCurrency:
                              getValues('cm_legal_entity').value === '16'
                                ? { name: 'MYR', value: 'MYR' }
                                : { name: 'USD', value: 'USD' },
                          })
                        );
                      }
                    }}
                  />
                </CenteredBoxFlex>
              </SubmissionRow>
            ))}
          </SubmissionSection>
          <SubmissionRow>
            {/* Reason for Discount Rate */}
            <SubmissionColumn widthRatio={'10'}>
              <Controller
                control={control}
                name="reason_for_discount_rate"
                render={({ field: { onChange, value, name }, fieldState: { invalid, error } }) => (
                  <TextInputArea
                    required={
                      curOfficialPricingDiscountType.value === 'percentage_discount' &&
                      curOfficialPricingDiscountValue >= 5
                    }
                    label="Reason for Discount Rate"
                    placeholder="Let us know if the discount rate is greater than or equal to 5% and also can leave the necessary verification information."
                    fieldName={name}
                    value={value}
                    onChange={onChange}
                    isError={invalid}
                    error={error?.message}
                  />
                )}
              />
              <Box
                as={motion.div}
                initial={'hide'}
                variants={fadeInOutMotion}
                animate={
                  curOfficialPricingDiscountType.value === 'percentage_discount' &&
                  curOfficialPricingDiscountValue >= 5
                    ? 'show'
                    : 'hide'
                }>
                <Spacer axis="vertical" css={{ $$size: '8px' }} />
                <Box
                  css={{
                    borderRadius: 6,
                    border: '1px solid $warning60',
                    backgroundColor: '$warning10',
                    px: 10,
                    py: 4,
                  }}>
                  <ButtonText size={12} css={{ color: '$warning90' }}>
                    If the discount rate exceeds 5%, it should be reviewed by the GM initially. If
                    it surpasses 10%, the CEO will need to review it.
                  </ButtonText>
                </Box>
              </Box>
            </SubmissionColumn>
          </SubmissionRow>
          <SubmissionRow>
            {/* Memo for Legal or Finance */}
            <SubmissionColumn widthRatio={'10'}>
              <Controller
                control={control}
                name="memo"
                render={({ field: { onChange, value, name }, fieldState: { invalid, error } }) => (
                  <TextInputArea
                    label="Memo for Legal or Finance"
                    placeholder="Inform the Legal and Finance departments of any applications for adjustments or special handling concerning Legal terms and Finance terms."
                    fieldName={name}
                    value={value}
                    onChange={onChange}
                    isError={invalid}
                    error={error?.message}
                  />
                )}
              />
            </SubmissionColumn>
          </SubmissionRow>
        </SubmissionSection>
      </SubmissionPaper>
      <Spacer axis="vertical" css={{ $$size: '80px' }} />
      {/* Wizard Bar */}
      <WizardBar
        css={{ left: navBarWidth }}
        animate={isNavBarOpen ? 'shrink' : 'grow'}
        variants={fixedLayoutGrowMotion(navBarWidth)}>
        {/* WizardBar Left*/}
        {/* Cancel */}
        <DialogAlertRoot>
          {/* Cancel Dialog Trigger */}
          <DialogAlertTrigger asChild>
            <Button
              size="md"
              variant="borderless"
              color="achromatic"
              css={{ mr: 'auto' }}
              data-track-id="btn-dialog-trigger-cancel">
              <ButtonText size={14} bold>
                Cancel
              </ButtonText>
            </Button>
          </DialogAlertTrigger>
          <DialogAlertPortal>
            <DialogAlertOverlay />
            {/* Cancel Dialog Content */}
            <DialogAlertContent css={{ px: 30, py: 20, borderRadius: '$10', maxWidth: '655px' }}>
              <Box css={{ display: 'flex', flexDirection: 'column', rowGap: 30 }}>
                {/* Cancel Dialog Header */}
                <Box css={{ display: 'flex', alignItems: 'center', columnGap: 15 }}>
                  <MSymbol iconName="error" weight={700} css={{ color: '$error60' }} />
                  <DialogAlertTitle asChild>
                    <Heading5 css={{ flexGrow: 1 }}>{`Leave site`}</Heading5>
                  </DialogAlertTitle>
                </Box>
                {/* Cancel Dialog Body */}
                <DialogAlertDescription css={{ pl: 40 }}>
                  <Text css={{ color: '$text-secondary' }}>
                    Are you sure you want to leave this page?
                  </Text>
                </DialogAlertDescription>
                {/* Cancel Dialog Footer */}
                <Box css={{ display: 'flex', columnGap: 20, justifyContent: 'flex-end' }}>
                  {/* Leave */}
                  <DialogAlertCancel asChild>
                    <Button
                      variant="borderless"
                      color="achromatic"
                      size="md"
                      disabled={
                        postAttachmentMutation.isLoading ||
                        postAppMutation.isLoading ||
                        patchAppMutation.isLoading
                      }
                      onClick={() => onLeaveClick()}
                      data-track-id="btn-leave">
                      <ButtonText size={14} bold>
                        Leave
                      </ButtonText>
                    </Button>
                  </DialogAlertCancel>
                  {/* Save */}
                  {getValues('status') === 'rejected' ? null : (
                    <Button
                      variant="outlined"
                      size="md"
                      onClick={() => onSaveClick()}
                      isLoading={
                        postAttachmentMutation.isLoading ||
                        postAppMutation.isLoading ||
                        patchAppMutation.isLoading
                      }
                      disabled={
                        postAttachmentMutation.isLoading ||
                        postAppMutation.isLoading ||
                        patchAppMutation.isLoading
                      }
                      css={{ width: appId ? 67 : 123 }}
                      data-track-id="btn-save">
                      <ButtonText size={14} bold>
                        {appId ? 'Save' : 'Save as draft'}
                      </ButtonText>
                    </Button>
                  )}
                  {/* Stay */}
                  <DialogAlertCancel asChild>
                    <Button
                      size="md"
                      disabled={
                        postAttachmentMutation.isLoading ||
                        postAppMutation.isLoading ||
                        patchAppMutation.isLoading
                      }
                      data-track-id="btn-stay">
                      <ButtonText size={14} bold>
                        Stay on this page
                      </ButtonText>
                    </Button>
                  </DialogAlertCancel>
                </Box>
              </Box>
            </DialogAlertContent>
          </DialogAlertPortal>
        </DialogAlertRoot>
        {/* WizardBar Right */}
        <Box css={{ display: 'flex', alignItems: 'center', columnGap: 20, ml: 'auto' }}>
          {/* Back */}
          <Button
            size="md"
            variant="outlined"
            onClick={() => onBackClick()}
            data-track-id="btn-back">
            <ButtonText size={14} bold>
              Back
            </ButtonText>
          </Button>
          {/* Next */}
          <Button type="submit" size="md" data-track-id="btn-next">
            <ButtonText size={14} bold>
              Next
            </ButtonText>
          </Button>
        </Box>
      </WizardBar>
    </form>
  );
};

export default ServiceInfo;
